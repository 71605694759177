import { AppName } from '@/types'
import rollbar from '../config/rollbar'
import ConfigContext from '../contexts/ConfigContext'

const devScheme = process.env.DEV_SSL ? 'https' : 'http'

const developersUrl = process.env.CANONICAL_HOST
  ? `https://developers.${process.env.CANONICAL_HOST}`
  : `${devScheme}://developers.local.gearflow.com:4000`

const suppliersUrl = process.env.CANONICAL_HOST
  ? `https://suppliers.${process.env.CANONICAL_HOST}`
  : `${devScheme}://suppliers.local.gearflow.com:4000`

const suppliersGqlWsUrl = process.env.CANONICAL_HOST
  ? `wss://suppliers.${process.env.CANONICAL_HOST}/socket/gql`
  : 'ws://suppliers.local.gearflow.com:4000/socket/gql'

const buyersUrl = process.env.CANONICAL_HOST
  ? `https://dashboard.${process.env.CANONICAL_HOST}`
  : `${devScheme}://dashboard.local.gearflow.com:4000`

const buyersGqlWsUrl = process.env.CANONICAL_HOST
  ? `wss://dashboard.${process.env.CANONICAL_HOST}/socket/gql`
  : 'ws://dashboard.local.gearflow.com:4000/socket/gql'

const adminUrl = process.env.CANONICAL_HOST
  ? `https://admin.${process.env.CANONICAL_HOST}`
  : `${devScheme}://admin.local.gearflow.com:4000`

const gfBaseUrl = process.env.CANONICAL_HOST
  ? `https://${process.env.CANONICAL_HOST}`
  : `${devScheme}://local.gearflow.com:4000`

const balanceDashboardBaseUrl =
  process.env.BALANCE_ENV === 'prod'
    ? 'https://dashboard.getbalance.com'
    : 'https://dashboard.sandbox.getbalance.com'

const stripeDashboardBaseUrl =
  process.env.BALANCE_ENV === 'prod'
    ? 'https://dashboard.stripe.com'
    : 'https://dashboard.stripe.com/test'

const buyersGqlTestingUrl = `${process.env.DEVS_BASE_URL}/parts-hub-graphql-client`

const dealersUrl = process.env.CANONICAL_HOST
  ? `https://sales.${process.env.CANONICAL_HOST}`
  : `${devScheme}://sales.local.gearflow.com:4000`

const dealersGqlWsUrl = process.env.CANONICAL_HOST
  ? `wss://sales.${process.env.CANONICAL_HOST}/socket/gql`
  : 'ws://sales.local.gearflow.com:4000/socket/gql'

const buyersMobileAppUrl = `gearflow-parts-hub://authenticated`

const stripePublicKey = `${process.env.STRIPE_PUBLISHABLE_KEY}`
const googleMapsApiKey = `${process.env.GOOGLE_MAPS_API_KEY}`

export const staticConfig = {
  developersUrl,
  suppliersUrl,
  suppliersGqlWsUrl,
  buyersGqlWsUrl,
  buyersUrl,
  adminUrl,
  gfBaseUrl,
  dealersUrl,
  dealersGqlWsUrl,
  balanceDashboardBaseUrl,
  stripeDashboardBaseUrl,
  buyersGqlTestingUrl,
  buyersMobileAppUrl,
  stripePublicKey,
  rollbar,
  convenienceFeeRate: 0.03,
  googleMapsApiKey,
}

const ConfigProvider = ({ app, children }: { app?: AppName; children: React.ReactNode }) => {
  const csrfToken = document
    .querySelector('meta[name=csrf-token]')
    ?.getAttribute('content') as string

  return (
    <ConfigContext.Provider value={{ ...staticConfig, csrfToken, app }}>
      {children}
    </ConfigContext.Provider>
  )
}

export default ConfigProvider
