import Rollbar from 'rollbar'

declare const gfConfig: string | undefined

type GfConfig = {
  rollbar: { enabled: boolean; captureUncaught: boolean; captureUnhandledRejections: boolean }
}

const defaultConfig = {
  rollbar: { enabled: true, captureUncaught: true, captureUnhandledRejections: true },
}

const extractConfig = (): GfConfig => {
  if (typeof gfConfig === 'undefined') return defaultConfig

  try {
    return JSON.parse(window.atob(gfConfig))
  } catch {
    return defaultConfig
  }
}

const config = extractConfig()

const rollbar: Rollbar.Configuration = {
  accessToken: process.env.ROLLBAR_JS_ACCESS_TOKEN,
  enabled: config.rollbar.enabled,
  captureUncaught: config.rollbar.captureUncaught,
  captureUnhandledRejections: config.rollbar.captureUnhandledRejections,
  hostSafeList: ['gearflow.com'],
  payload: {
    environment: process.env.ROLLBAR_ENV,
    client: {
      javascript: {
        code_version: '1.0.0',
        source_map_enabled: true,
      },
    },
  },
}

export default rollbar
